import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import langEs from "./lang/es.json";
import langEn from "./lang/en.json";
import langMx from "./lang/mx.json";
import langCo from "./lang/co.json";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// Inicializar i18n
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: langEn },
    es: { translation: langEs },
    mx: { translation: langMx },
    co: { translation: langCo },
  },
  lng: "es",
  fallbackLng: "es",
  interpolation: {
    escapeValue: false,
  },
});

// Crear una instancia del cliente de consulta
const queryClient = new QueryClient();

// Renderizar la aplicación
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        {" "}
        {/* Proveer QueryClient */}
        <App />
        <ReactQueryDevtools initialIsOpen={true} />
      </HelmetProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// Medición de rendimiento
reportWebVitals();
