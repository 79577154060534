import { useQuery } from "@tanstack/react-query";
import { fetchDataAll } from "./ApiServices";

export const useFetchData = (endpoint, lang) => {
  return useQuery({
    queryKey: ["data", endpoint, lang],
    queryFn: () => fetchDataAll(endpoint, lang),
    enabled: !!endpoint && !!lang,
    staleTime: 5 * 60 * 1000,
    retry: 2,
  });
};

export const useFetchCustom = (
  queryKey,
  fetchFunction,
  params = [],
  options = {}
) => {
  return useQuery({
    queryKey,
    queryFn: () => fetchFunction(...params),
    enabled: params.every((param) => param !== null && param !== undefined),
    staleTime: 5 * 60 * 1000,
    retry: 2,
    ...options,
  });
};
