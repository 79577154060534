export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const DataStreaming = [
  {
    logo: "/img/logos/hbomax.svg",
  },
  {
    logo: "/img/logos/prime.svg",
  },
  {
    logo: "/img/logos/netflix.svg",
  },
];
