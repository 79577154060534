import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { isMobile } from "react-device-detect";
import Modal from "react-bootstrap/Modal";
import { fetchDataAll } from "../../services/ApiServices";
import InterSection from "../Utils/InterSection";
import TarjetaTarifa from "../Tarjeta/TarjetaTarifa";
import NotInfoItem from "../Utils/NotInfoItem";
import Load from "../Utils/Load";
import {
  fetchFilterPlanCelular,
  fetchTarifasPlanCelular,
} from "../../services/ApiServices";
import { useLocation } from "react-router-dom";
import { useFetchCustom } from "../../services/Query";

function ContenedorProductosPlanCelular() {
  // Estado para filtros de precio y capacidad
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [typeMoneda, setTypeMoneda] = useState(null);

  // Estados para el estado de carga de filtros e información
  const [isLoadFilter, setIsLoadFilter] = useState(false);
  const [isLoadInformation, setIsLoadInformation] = useState(false);

  // Estados para filtros seleccionados
  const [filterBrand, setFilterBrand] = useState([]);
  const [filterPrice, setFilterPrice] = useState([minPrice, maxPrice]);

  const [filterTechnology, setFilterTechnology] = useState(false);
  const [filterMessage, setFilterMessage] = useState(false);
  const [filterRoaming, setFilterRoaming] = useState(false);
  const [filterPermanencia, setFilterPermanencia] = useState(false);
  const [filterPromo, setFilterPromo] = useState(false);

  // Estados para tarifas y marcas
  const [Tarifas, setTarifas] = useState([]);
  const [filtros, setFiltros] = useState([]);
  const [brand, setBrand] = useState([]);

  // Estados para rangos de precio y capacidad
  const [rangePrice, setRangePrice] = useState([minPrice, maxPrice]);

  const [lang, setLang] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setLang(location.pathname.split("/")[1]);
  }, [location, lang]);

  // Estado para el modal de filtros
  const [show, setShow] = useState(false);

  // Función para limpiar los filtros
  const cleanFilter = () => {
    setFilterBrand([]);
    setFilterPrice([minPrice, maxPrice]);
    setRangePrice([minPrice, maxPrice]);
    setFilterPromo(false);
    setFiltros(Tarifas);
    setFilterPermanencia(false);
  };

  // Función para manejar el cambio en el rango de precio
  const handleRangeChangePrice = (newRange) => {
    setRangePrice(newRange);
    handleFilterPrice(newRange);
  };

  const { data: filterData } = useFetchCustom(
    ["filterData", "filterPlanCelular", lang],
    fetchFilterPlanCelular,
    [lang],
    { enabled: lang != null }
  );

  useEffect(() => {
    if (filterData) {
      setTypeMoneda(filterData.moneda);
      setMaxPrice(filterData.maxPrice);
      setMinPrice(filterData.minPrice);
      setRangePrice(filterData.rangePrice);
      setIsLoadFilter(true);
    }
  }, [filterData]);

  useEffect(() => {
    if (lang != null) {
      const fetchBrands = async () => {
        try {
          const brands = await fetchDataAll("OperadorasPlanCelular", lang);
          setBrand(brands);
        } catch (error) {
          console.error("Error al obtener las marcas de operadoras:", error);
        }
      };

      fetchBrands();
    }
  }, [lang]);

  // Función para obtener las tarifas de móvil
  useEffect(() => {
    if (lang != null) {
      const fetchTariffs = async () => {
        try {
          setIsLoadInformation(true);
          const response = await fetchTarifasPlanCelular(lang);
          setFiltros(response);
          setTarifas(response);
          setIsLoadInformation(false);
        } catch (error) {
          console.error("Error al obtener las tarifas de fibra:", error);
        }
      };

      fetchTariffs();
    }
  }, [brand, lang]);

  function setFilterBrandMulti(value) {
    if (!filterBrand?.includes(value)) {
      setFilterBrand([...filterBrand, value]);
    } else {
      setFilterBrand(filterBrand.filter((item) => item !== value));
    }
  }

  // Función para manejar el filtro de precio
  const handleFilterPrice = (value) => {
    setFilterPrice(value);
  };

  // Función para filtrar por marca
  const filterByBrand = useCallback(
    (item) => {
      if (filterBrand.length > 0) {
        return filterBrand.includes(item.operadora);
      } else {
        return true;
      }
    },
    [filterBrand] // Dependencia de filterBrand
  );

  // Función para filtrar por precio
  const filterByPrice = useCallback(
    (item) =>
      filterPrice !== null
        ? item.precio >= filterPrice[0] && item.precio <= filterPrice[1]
        : true,
    [filterPrice] // Dependencia de filterPrice
  );

  // Función para filtrar por promoción
  const filterByPromo = useCallback(
    (item) =>
      filterPromo !== false
        ? item.promocion !== "" && item.promocion !== null
        : true,
    [filterPromo] // Dependencia de filterPromo
  );

  useEffect(() => {
    const resultado = Tarifas.filter((item) => filterByBrand(item))
      .filter((item) => filterByPrice(item))
      .filter((item) => filterByPromo(item));

    setFiltros(resultado);
  }, [
    Tarifas,
    filterBrand,
    filterPrice,
    filterPromo,
    filterByBrand,
    filterByPrice,
    filterByPromo,
  ]);

  return (
    <>
      <section>
        <Container>
          <Row className="justify-content-around">
            <Col xs={12} md={12} xl={3}>
              <Row>
                {!isMobile ? (
                  <Col className="my-3 font-semibold" xs={6} md={12} xl={5}>
                    Filtrar por:{" "}
                  </Col>
                ) : (
                  <Col className="my-2" xs={6} md={5}>
                    <Button variant="light" onClick={() => setShow(true)}>
                      Filtrar por
                    </Button>
                  </Col>
                )}
                <Col className="my-2 text-center" xs={6} md={7}>
                  <button className="btn btn-light" onClick={cleanFilter}>
                    Limpiar filtro
                  </button>
                </Col>
                <hr />
              </Row>
              {isMobile ? (
                <Modal show={show} onHide={() => setShow(false)}>
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <Row>
                      {isMobile && (
                        <Col xs={12} key={filterBrand} className="my-2" md={6}>
                          Se encontraron{" "}
                          <span className="font-bold">{filtros?.length}</span>{" "}
                          resultados de{" "}
                          <span className="font-bold">{Tarifas.length}</span>
                        </Col>
                      )}
                      <Col md={12}>
                        <span className="font-bold">Compañía:</span>
                      </Col>
                      {brand?.length > 0 &&
                        brand.map((item, index) => (
                          <Col xs={4} md={6} key={item.id}>
                            <button
                              className={`filtro-producto-logo my-2 ${
                                filterBrand.includes(item.id) ? "logoFocus" : ""
                              }`}
                              value={item.nombre}
                              onClick={() => setFilterBrandMulti(item.id)}
                            >
                              <img src={item.logo} alt={item.nombre} />
                            </button>
                          </Col>
                        ))}
                    </Row>
                    <Row>
                      <div className="mt-4">
                        <b>{"Coste mensual"}:</b>
                        <div className="my-4">
                          {typeMoneda}
                          {rangePrice[0]
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                          - {typeMoneda}
                          {rangePrice[1]
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </div>
                        <Slider
                          range
                          min={minPrice}
                          max={maxPrice}
                          value={rangePrice}
                          onChange={handleRangeChangePrice}
                          className="form-input-range"
                        />
                      </div>
                      <div className="my-2">
                        <b>{"5G"}:</b>
                        <div className="my-2">
                          <Form.Switch
                            className="input-check-dark mt-2 text-left"
                            type="switch"
                            checked={filterTechnology}
                            onChange={() =>
                              setFilterTechnology(!filterTechnology)
                            }
                            label={"Mostrar solo ofertas 5G"}
                            reverse
                          />
                        </div>
                      </div>
                      <div className="my-2">
                        <b>{"Mensajes"}:</b>
                        <div className="my-2">
                          <Form.Switch
                            className="input-check-dark mt-2 text-left"
                            type="switch"
                            checked={filterMessage}
                            onChange={() => setFilterMessage(!filterMessage)}
                            label={"Mensajes ilimitados"}
                            reverse
                          />
                        </div>
                      </div>
                      <div className="my-2">
                        <b>{"Roaming"}:</b>
                        <div className="my-2">
                          <Form.Switch
                            className="input-check-dark mt-2 text-left"
                            type="switch"
                            checked={filterRoaming}
                            onChange={() => setFilterRoaming(!filterRoaming)}
                            label={"Roaming en la UE"}
                            reverse
                          />
                        </div>
                      </div>
                      {/* <div className='my-2'>
                        <b>{'Promoción'}:</b>
                        <div className='my-2'>
                          <Form.Switch
                            className='input-check-dark mt-2 text-left'
                            type='switch'
                            checked={filterPromo}
                            onChange={() => setFilterPromo(!filterPromo)}
                            label={'Tiene promoción'}
                            reverse
                          />
                        </div>
                      </div> */}
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={() => setShow(false)}>
                      Filtrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : (
                <>
                  {isLoadFilter && !isLoadInformation ? (
                    <div>
                      <Row>
                        {isMobile && (
                          <Col
                            xs={12}
                            key={filterBrand}
                            className="my-2"
                            xl={6}
                          >
                            Se encontraron{" "}
                            <span className="font-bold">{filtros?.length}</span>{" "}
                            resultados de{" "}
                            <span className="font-bold">{Tarifas.length}</span>
                          </Col>
                        )}
                        <Col md={12}>
                          <span className="font-semibold">Compañía:</span>
                        </Col>
                        {brand?.length > 0 &&
                          brand.map((item, index) => (
                            <Col xs={4} xl={6} key={item.id}>
                              <button
                                className={`filtro-producto-logo my-2 ${
                                  filterBrand.includes(item.id)
                                    ? "logoFocus"
                                    : ""
                                }`}
                                value={item.nombre}
                                onClick={() => setFilterBrandMulti(item.id)}
                              >
                                <img src={item.logo} alt={item.nombre} />
                              </button>
                            </Col>
                          ))}
                      </Row>
                      <Row>
                        <div className="mt-4">
                          <b>{"Coste mensual"}:</b>
                          <div className="my-4">
                            {typeMoneda}
                            {rangePrice[0]
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                            - {typeMoneda}
                            {rangePrice[1]
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </div>
                          <Slider
                            range
                            min={minPrice}
                            max={maxPrice}
                            value={rangePrice}
                            onChange={handleRangeChangePrice}
                            className="form-input-range"
                          />
                        </div>
                        <div className="mt-4">
                          <b>{"Permanencia"}:</b>
                          <Form.Switch
                            className="input-check-dark mt-2 text-left"
                            type="switch"
                            checked={filterPermanencia}
                            onChange={() =>
                              setFilterPermanencia(!filterPermanencia)
                            }
                            label={"Tarifa sin permanencia"}
                            reverse
                          />
                        </div>
                        {/* <div className='my-2'>
                          <b>{'Promoción'}:</b>
                          <div className='my-2'>
                            <Form.Switch
                              className='input-check-dark mt-2 text-left'
                              type='switch'
                              checked={filterPromo}
                              onChange={() => setFilterPromo(!filterPromo)}
                              label={'Tiene promoción'}
                              reverse
                            />
                          </div>
                        </div> */}
                      </Row>
                    </div>
                  ) : (
                    <Load />
                  )}
                </>
              )}
            </Col>
            <Col md={12} xl={8}>
              <Row>
                <Col key={filterBrand} className="my-2" xl={6}>
                  Mostrando:{" "}
                  <span className="font-bold">{filtros?.length}</span>{" "}
                  resultados de{" "}
                  <span className="font-bold">{Tarifas.length}</span>
                </Col>
              </Row>
              <Row>
                <div className="pruebaPos">
                  {!isLoadInformation ? (
                    filtros?.length > 0 ? (
                      filtros?.map((item, index) => (
                        <TarjetaTarifa key={index} data={item} />
                      ))
                    ) : (
                      <NotInfoItem
                        key={0}
                        title={"No se encontraron ofertas"}
                        text={
                          "Lo sentimos, no hemos encontrado ofertas con los filtros seleccionados."
                        }
                      />
                    )
                  ) : (
                    <Load></Load>
                  )}
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <InterSection></InterSection>
    </>
  );
}

export default ContenedorProductosPlanCelular;
