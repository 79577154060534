import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TitleSection from "../Text/TitleSection";
import FormSuscripcion from "../Forms/FormSuscripcion";
import { getBlogById } from "../../services/ApiServices";
import { useLocation } from "react-router-dom";
import Load from "../Utils/Load";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MetaData from "../../Components/Header/SeoMetadata";

export default function ContenedorBlogItem() {
  const [fetchBlog, setFetchBlog] = useState(null);
  const [idBlog, setIdBlog] = useState(null);
  const [landing, setLanding] = useState(null);
  const [btnBack, setBtnBack] = useState(null);
  const [validarCategoria, setValidarCategoria] = useState(null);
  const [load, setLoad] = useState(false);
  const [lang, setLang] = useState(null);
  const [metaData, setMetaData] = useState({
    titulo: "Título por defecto",
    descripcion: "Descripción por defecto",
    imagen_destacada: "/ruta/por/defecto.jpg",
  });

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setLang(location.pathname.split("/")[1]);
  }, [location]);

  useEffect(() => {
    const pathname = location.pathname;
    const locations = pathname.split("/");
    setLanding(locations[2]?.toLowerCase());
    setValidarCategoria(locations[3]?.toLowerCase());
    setIdBlog(locations[4]);
  }, [location]);

  useEffect(() => {
    const fetchBlogId = async () => {
      setLoad(true);
      try {
        if (idBlog) {
          if (landing === "blog") {
            const response = await getBlogById(lang, validarCategoria, idBlog);
            if (
              !response ||
              (!response.categoria_slug.includes(validarCategoria) &&
                validarCategoria !== "destacados")
            ) {
              navigate("/es/404", {
                replace: true,
                state: { statusCode: 404 },
              });
            } else {
              setFetchBlog(response);
              setBtnBack("blog");
            }
          }
        }
      } catch (error) {
        console.error("Error al obtener blog:", error);
      } finally {
        setLoad(false);
      }
    };

    fetchBlogId();
  }, [lang, validarCategoria, idBlog, landing, navigate]);

  useEffect(() => {   
    if (fetchBlog) {
      setMetaData({
        titulo: fetchBlog.titulo || "Título por defecto",
        descripcion: fetchBlog.entradilla || "Descripción por defecto",
        imagen_destacada: fetchBlog.imagen || "/ruta/por/defecto.jpg",
      });
    }
  }, [fetchBlog]);

  return (
    <>
      <MetaData
        titulo={metaData.titulo}
        descripcion={metaData.descripcion}
        imagen_destacada={metaData.imagen_destacada}
      />
      {!load ? (
        <Container>
          <Row>
            <Col xs={12}>
              <TitleSection
                left
                title={fetchBlog?.titulo}
                textBlog={fetchBlog?.contenido}
                clave={fetchBlog?.hashtags
                  ?.replaceAll("[", "")
                  .replaceAll('"', "")
                  .replaceAll("]", "")
                  .replaceAll(",", " ")}
                fecha={fetchBlog?.fecha_publicacion}
                autor={fetchBlog?.autor}
                imagen={fetchBlog?.imagen}
              />
              <Col xs={12} className="text-center my-5">
                <Link className="font-09 btn btn-primary" to={`/es/${btnBack}`}>
                  Volver
                </Link>
              </Col>
            </Col>
          </Row>
          <Row>
            <FormSuscripcion />
          </Row>
        </Container>
      ) : (
        <Load />
      )}
    </>
  );
}
