import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Title from "../../Components/Text/Title";
import MetaData from "../../Components/Header/SeoMetadata";

export default function PoliticaPrivacidadCo() {
  return (
    <>
      <MetaData titulo={""} descripcion={""} />
      <Header breadCrumb></Header>
      <div className="mb-5">
        <Container>
          <Row>
            <Col md={12}>
              <Title title={"Política de"} titleAlt={"privacidad"} />
              <p>
                De conformidad con lo dispuesto en la normativa aplicable de
                protección de datos de carácter personal, a continuación, te
                contamos cómo tratamos tus datos personales.
              </p>
              <p>
                Con carácter general, te informaremos del carácter obligatorio
                de la recogida de determinados datos de carácter personal en los
                campos indicados mediante un asterisco (*). La no
                cumplimentación de dichos datos podrá impedir prestar todos
                aquellos servicios relacionados a tales datos, liberándonos de
                toda responsabilidad por la falta de prestación o prestación
                incompleta de los mismos.
              </p>
              <Title
                title={"¿Quién es el "}
                titleAlt={"Responsable de Tratamiento?"}
              />
              <p>
                El responsable del tratamiento es Kolondoo Media, S.L. sociedad
                de nacionalidad española, con NIF B-16756041, y domicilio social
                en la Calle Barquillo, 8 planta, 1º izquierda, 28004 Madrid
                (España), debidamente está inscrita en el Registro Mercantil de
                Madrid, Tomo 42273, Folio 180, Inscripción 1, Hoja M-748395 (en
                adelante, “la Sociedad” o “nosotros” indistintamente). Email
                Delegado de Protección de datos: <b>dpd@kolondoo.com</b>
              </p>
              <p>
                La Sociedad, entre otras actividades, se centra en el sector de
                la publicidad a través de marketing directo, marketing de
                afiliación, y publicidad online con el previo consentimiento del
                destinatario. Asimismo, funcionamos como comparador de tarifas o
                precios de servicios y productos de terceros destinados al “
                Ahorro en el hogar,” entre otros: telefonía e internet, agua,
                luz y gas, operando para estos productos como una empresa de
                servicios conforme a la Ley 34/2002 de 11 de julio, de servicios
                de la sociedad de la información y de comercio electrónico.
                Consulta qué sectores se encuentran incluidos dentro de la
                categoría “Ahorro en el hogar” más abajo.
              </p>
              <Title
                title={"¿Cuál es la edad mínima para"}
                titleAlt={"registrarse en el sitio web?"}
              />
              <p>
                El registro en el sitio web está limitado a usuarios mayores de
                dieciocho (18) años. Los menores de edad no podrán usar los
                servicios ofrecidos sin la previa autorización de sus padres,
                tutores o representantes legales, quienes serán los únicos
                responsables de todos los actos realizados a través del sitio
                web por los menores a su cargo, incluyendo la cumplimentación de
                los formularios con los datos personales de dichos menores y la
                marcación, en su caso, de las casillas que los acompañan. Al
                registrarse en el sitio web, garantizas que eres mayor de edad.
              </p>
              <p>
                Toda la información de registro que envíes para registrarte debe
                ser verdadera, precisa, completa y actualizada, siendo, en
                consecuencia, el único responsable de los daños, directos o
                indirectos, que puedan derivarse de cualquier incumplimiento de
                dicha obligación. Si los datos proporcionados pertenecen a un
                tercero, garantizas que previamente has informado a dicho
                tercero de los aspectos aquí recogidos y has obtenido su
                consentimiento expreso e informado con los fines especificados
                en la presente Política de Privacidad.
              </p>
              <Title title={"¿Qué datos personales recabamos?"} />
              <p>
                A continuación, te detallamos el tipo de dato que recabamos y
                cómo:
              </p>
              <ol type="I" className="">
                <li className="my-3">
                  <b>
                    Información proporcionada de forma directa a través de los
                    formularios de registro:
                  </b>{" "}
                  La información de registro puede incluir, entre otros datos,
                  nombre, apellidos, dirección de correo electrónico, teléfono,
                  género, país, código postal, fecha de nacimiento, áreas de
                  interés con el fin de que podamos gestionar nuestros listados
                  de correos y enviarle publicidad personalizada online.
                  Asimismo, siempre que un usuario visita nuestro sitio web, se
                  identifica y registra automáticamente en nuestras base de
                  datos la dirección IP, la fecha y hora de la visita y
                  condiciones aceptadas como mecanismo para verificar (i) la
                  generación efectiva de un lead por parte del interesado; (ii)
                  la obtención del consentimiento y aceptación del interesado de
                  la presente Política de Privacidad así como nuestra Política
                  de Cookies; (iii) el control de IP extranjeras o duplicados;
                  (iv) la detección de fraudes mediante la utilización de robots
                  generadores de registros falsos; (v) el control y comunicación
                  de leads generados a favor de los clientes.
                  <br />
                  <br />
                  Al suscribirte, podremos pedir que previamente verifiques tu
                  dirección de email. Para ello, recibirás un correo electrónico
                  con el asunto “Cuenta de usuario registrada con éxito” o
                  asunto similar en tu bandeja de entrada.
                </li>
                <li className="my-3">
                  <b>
                    Información proporcionada de forma directa a través del
                    formulario de contacto:
                  </b>{" "}
                  Utilizaremos tu nombre y dirección de correo electrónico para
                  atender cualquier petición, consulta, sugerencia y/o asunto
                  profesional y poder facilitarte la información correspondiente
                  en su caso.
                </li>
                <li className="my-3">
                  <b>Información de las Newsletters:</b> Como suscriptor, si has
                  aceptado recibir nuestra Newsletter, podremos utilizar la
                  dirección de correo electrónico para enviarte periódicamente
                  comunicaciones con información sobre nuestros servicios,
                  novedades relacionadas a los servicios de comparación en los
                  sectores indicados y/o información que resulte de utilidad e
                  interés tomando como referencia las preferencias
                  seleccionadas.
                </li>
                <li className="my-3">
                  <b>Redes sociales:</b> Si accedes o te registras a través de
                  una red social o conectas un servicio de la Sociedad a una red
                  social, autorizas a la Sociedad a que obtenga, almacene y use
                  los datos personales de dicha red social para su tratamiento
                  de acuerdo con esta Política de Privacidad. Los datos que
                  obtengamos podrán incluir la identidad o nombre de usuario
                  asociado a dicha red social y cualquier información que
                  permitas a la red social compartir con nosotros como, por
                  ejemplo, foto de perfil, fecha de nacimiento o dirección de
                  correo electrónico, y, en general, cualquier otro dato
                  personal que hayas hecho público a través de dicha red social.
                  <br />
                  Te recordamos que las redes sociales pueden recoger gran
                  cantidad de datos personales e información, tales como las
                  páginas web que has visitado y la dirección IP, pudiendo
                  instalar cookies en el dispositivo utilizado para permitir el
                  correcto funcionamiento de los servicios de la red social. La
                  Sociedad no es responsable de la seguridad o privacidad de la
                  información recogida por tales terceros. Por ello, te
                  recomendamos que consultes las respectivas políticas de
                  privacidad de dichas redes sociales para poder entender el
                  tratamiento de tus datos personales que realizan antes de dar
                  tu consentimiento. Asimismo, deberás configurar estos
                  servicios de acuerdo con tus intereses.
                </li>
                <li className="my-3">
                  <b>Datos de actividad:</b> Cuando accedas e interactúes con
                  nuestros servicios, podremos obtener cierta información sobre
                  dichas acciones incluyendo tu interacción con el contenido y
                  los servicios disponibles a través de los servicios. Por
                  ejemplo, con el fin de permitir su conexión a los servicios de
                  la Sociedad, nuestros servidores reciben y almacenan
                  información sobre tu terminal y navegador, incluyendo
                  potencialmente tu dirección IP, tipo de navegador, y otra
                  información sobre el software o hardware utilizado. También
                  podremos instalar cookies y otras tecnologías de rastreo
                  (tales como pixels). Estas tecnologías podrán utilizarse para
                  obtener y almacenar información sobre el uso que hagas de
                  nuestro sitio web y la forma en la que interactúas con
                  nuestros servicios como, por ejemplo, las páginas y otros
                  contenidos que visualices, las búsquedas que realices o los
                  anuncios que te sean mostrados. Para obtener más información,
                  por favor, visita nuestra{" "}
                  <a className="color-primary no-link" href="https://vuskoo.com/co">
                    <b>Política de Cookies</b>
                  </a>
                  .
                </li>
                <li className="my-3">
                  <b>Información proporcionada durante llamadas:</b> En algunos
                  casos, te solicitaremos información que resulta imprescindible
                  para poder ofrecerte los servicios relacionados con una
                  comparativa o la necesaria para la contratación de alguna de
                  las ofertas ofrecidas (tales como, por ejemplo, el documento
                  de identificación, tu dirección postal o la cuenta bancaria).
                  Recuerda que, si no nos facilitas dicha información o no
                  consientes la cesión, será imposible ofrecerte el servicio o
                  producto que desees contratar.
                </li>
                <li className="my-3">
                  <b>Información de otras fuentes:</b> Podremos complementar la
                  información que recogemos con información de otras fuentes,
                  tales como información públicamente accesible en servicios de
                  redes sociales y fuentes comercialmente disponibles o aquella
                  que libremente nos proporciones.
                </li>
              </ol>
              <p>
                Recuerda que, en cualquier momento, puedes revocar el
                consentimiento o informarnos de que no deseas continuar suscrito
                a ninguno de nuestros servicios, siguiendo las indicaciones
                recogidas en esta Política de Privacidad. Eres el único
                responsable de mantener la confidencialidad de tus claves de
                acceso y uso de la cuenta. En caso de un uso no autorizado o
                acceso a tu cuenta, por favor, comunícate inmediatamente con
                nosotros y cambia las claves de acceso desde tu panel de
                usuario.
              </p>
              <Title
                title={"¿Con qué finalidades "}
                titleAlt={"tratamos tus datos?"}
              />
              <p>
                Utilizamos los datos personales que recogemos para las
                siguientes finalidades:
              </p>
              <ol type="a">
                <li className="my-3">
                  Ofrecer los servicios de la Sociedad con carácter general.
                </li>
                <li className="my-3">
                  Atender solicitudes de información y consoltas.
                </li>
                <li className="my-3">
                  Ofrecer la comparativa que nos solicites de productos y
                  servicios de terceros de los sectores que se indican abajo y
                  la consiguiente contratación de los productos si así lo
                  solicitas. Ello supone que te relacionarás no sólo con la
                  Sociedad sino también con terceros prestadores de los
                  servicios que solicitas.
                </li>
                <li className="my-3">
                  Grabar las llamadas telefónicas que mantengas con nosotros
                  para ofrecerte la información solicitada y llevar a cabo la
                  contratación del producto y/o servicio ofertado. Cuando te
                  comuniques con nosotros por teléfono, te informaremos de que
                  dicha llamada será grabada, y podremos conservar la misma para
                  administrar las solicitudes de información, permitir la
                  prestación del servicio, contestar a tus solicitudes, mejorar
                  la calidad de nuestros servicios y servir como elemento de
                  prueba en caso de cualquier reclamación o duda sobre los
                  servicios.
                </li>
                <li className="my-3">
                  Permitir participar y suscribirse, cuando sea posible, a
                  promociones, concursos y/o sorteos.
                </li>
                <li className="my-3">
                  Permitir iniciar sesión en los servicios de la Sociedad a
                  través de una red social.
                </li>
                <li className="my-3">
                  Gestionar los procesos de selección de personal, presentes o
                  futuros, llevados a cabo por la Sociedad.
                </li>
                <li className="my-3">
                  Permitir la navegación, personalización y configuración de
                  alertas de nuestro sitio a través de cookies técnicas y
                  funcionales con el fin de mantener nuestras herramientas
                  seguras y permitir que funcionen correctamente y mejoren
                  continuamente.
                </li>
                <li className="my-3">
                  Elaborar informes estadísticos y/o de investigación
                  relacionados con la actividad del usuario durante la
                  navegación en el sitio web o su participación en acciones
                  publicitarias o promocionales de forma anonimizada.
                </li>
                <li className="my-3">
                  Mantener listas de supresión actualizadas para asegurarnos que
                  no eres contactado si así se ha solicitado a través de listas
                  de supresión oficiales, socios con los que podamos colaborar o
                  a través del ejercicio de tus derechos.
                </li>
                <li className="my-3">
                  Verificar la inexistencia de actuaciones fraudulentas o
                  contrarias a los términos y condiciones del sitio web o las
                  Políticas de Privacidad, pudiendo eliminar justificadamente a
                  cualquier usuario que las incumpla.
                </li>
                <li className="my-3">
                  Remitir comunicaciones comerciales (Newsletter): Enviarte (vía
                  correo electrónico, SMS, teléfono, redes sociales u otro medio
                  de comunicación que esté, en su caso, habilitado) ofertas,
                  promociones u otras comunicaciones comerciales relacionadas
                  con servicios y/o productos de la Sociedad, entidades
                  vinculadas y/o de terceros asociados que operen en los
                  sectores recogidos en la presente política así como, en su
                  caso, otros sectores de en los que puedas estar interesado de
                  acuerdo con los criterios del sitio web, la configuración de
                  cookies elegida y/o las comparativas solicitadas. Solamente
                  nos pondremos en contacto contigo con esta finalidad, cuando
                  nos lo hayas solicitado expresamente, o cuando hayas
                  solicitado previamente cualquiera de nuestros servicios y no
                  te hayas opuesto o nos hayas comunicado que no quieres recibir
                  estas comunicaciones.
                  <br />
                  <br />
                  La finalidad del tratamiento es la realización de ofertas,
                  promociones, comercialización y contratación de productos y
                  servicios destinados al “Ahorro en el hogar” en los siguientes
                  sectores: seguros, finanzas y banca, telefonía, tecnología
                  telecomunicaciones, energía y agua, luz y gas, inmobiliario,
                  mobiliario, comercio minorista relacionados con el hogar
                  (entre otros, artículos de consumo, electrónica, jardín,
                  juguetes, niños, electrodomésticos, farmacia y mascotas)
                  vigilancia y seguridad.
                </li>
              </ol>
              <p>
                Por favor, recuerda que, al seleccionar la casilla
                correspondiente, estás prestando tu consentimiento libre y
                expreso para recibir publicidad propia y de terceros de los
                sectores indicados. En cualquier momento, puedes revocar el
                consentimiento o informarnos si no quieres continuar suscrito a
                todos o alguno de nuestros servicios, siguiendo las indicaciones
                recogidas en esta Política de Privacidad.
              </p>
              <Title title={"¿Cuál es la base legitimadora?"} />
              <p>
                La base legitimadora para las finalidades a) – d) es la gestión
                necesaria para la prestación de los servicios propios de la
                Sociedad (Art. 6.1.(b) RGPD) y la aplicación de medidas
                precontractuales o ejecución de los contratos con los
                distribuidores finales (por ejemplo, la empresa de aseguradora).
                Sin ello no es posible la prestación de los mismos.
              </p>
              <p>
                La base legitimadora para las finalidades e), f), g), h), y m)
                es el consentimiento informado y expreso por tu parte (Art.
                6.1.(a) RGPD). Este es discrecional, pero sin su consentimiento
                no podrán realizarse las finalidades asociadas, en especial
                poder proporcionarle comunicaciones genéricas de servicios y
                productos de la Sociedad y/o terceros o comunicaciones basadas
                en sus intereses y preferencias.
              </p>
              <p>
                Finalmente, en ocasiones trataremos tus datos para nuestros
                intereses comerciales que no vayan en contra de tus derechos,
                manteniendo, un equilibrio entre nuestros intereses y los tuyos.
                Por ello, la base legitimadora para las finalidades i), j), k) y
                l) es el interés legítimo de la Sociedad. (Art. 6.1.(f) RGPD),
                pudiendo, sin embargo, retirar el consentimiento en cualquier
                momento.
              </p>
              <Title
                title={"¿Durante cuánto tiempo"}
                titleAlt={"conservaremos los datos personales?"}
              />
              <p>
                Con carácter general, los datos personales serán conservados
                mientras no solicites su supresión, limitación del tratamiento o
                los mismos ya no sean necesarios para los fines que fueron
                recogidos después de un período de inactividad (sin interacción
                activa con las comunicaciones comerciales o los terceros) de
                tres (3) años.
              </p>
              <p>
                No obstante, lo anterior, los datos que nos hayan llegado a
                través de una solicitud del formulario de contacto los
                mantendremos durante el tiempo necesario para resolver tus dudas
                o atender tus peticiones.
              </p>
              <p>
                En el caso de que tengamos tus datos por tu candidatura a una
                oferta de trabajo, los mantendremos durante un plazo máximo de
                un (1) año. Entendemos que este período es razonable para que
                pudiera surgir una nueva oportunidad laboral o decidieras
                contactar con nosotros para alguna contratación o cualquier
                servicio de tu interés.
              </p>
              <p>
                Una vez transcurridos los plazos anteriores, durante un plazo de
                cinco (5) años y/o el máximo de tiempo legalmente permitido, los
                datos se conservarán debidamente bloqueados a disposición de
                administraciones públicas, jueces y tribunales para la atención
                de posibles responsabilidades derivadas de la relación mantenida
                y cumplir con toda obligación legal que nos sea requerida.
              </p>
              <p>
                Cuando ya no necesitemos usar sus datos personales, se
                eliminarán de nuestros sistemas y registros o se anonimizarán
                para que no sean identificables.
              </p>
              <Title title={"¿A qué destinatarios se comunicarán sus datos?"} />
              <p>
                Todas las cesiones que se indican a continuación resultan
                necesarias para el cumplimiento de las finalidades anteriormente
                señaladas o bien se llevan a cabo en cumplimiento de una
                obligación legal. Los datos personales podrán ser cedidos a:
              </p>
              <ol type="list">
                <li>
                  Empresas asociadas con el fin de permitir la contratación de
                  los productos o servicios que solicites. Si te hemos
                  proporcionado información de un producto o servicio de
                  terceros que te interesa, necesitamos conocer tus datos para
                  trasladárselos o cedérselos al prestador de los servicios
                  seleccionados, con el fin de que pueda darte de alta como
                  cliente y pueda prestarte el servicio deseado. Consulta el
                  apartado “Tratamientos de datos de terceros” más abajo.
                </li>
                <li>
                  Autoridades competentes, Administraciones Públicas y Fuerzas y
                  Cuerpos de Seguridad del Estado cuando estas lo requieran a
                  los efectos de cumplir con las leyes aplicables.
                </li>
              </ol>
              <p>
                Asimismo, te recordamos que es posible que terceros proveedores
                de servicios a los que la Sociedad encomienda todo o parte de la
                prestación de determinados servicios, tengan acceso a la
                información de carácter personal en calidad de encargados de
                tratamiento como, por ejemplo, proveedores de alojamiento,
                mantenimiento y soporte en nuestras bases de datos, consultores
                y asesores o entidades vinculadas, para fines administrativos o
                de gestión unificada de los recursos en el marco de la
                prestación de servicios.
              </p>
              <Title title={"Tratamientos de datos de"} titleAlt={"terceros"} />
              <p>
                A efectos aclaratorios oportunos, esta Política de Privacidad no
                es aplicable a los datos recogidos por terceros a través de sus
                sitios web, incluso si alguno de dichos sitios web contiene
                enlaces desde y hacia nuestro sitio web. Asimismo, recuerda que
                el tercero que te suministre el producto o servicio contratado
                tendrá su propia política de privacidad que deberás consultar
                antes de contratar el servicio. La Sociedad no es responsable de
                ningún contenido dentro de las respectivas políticas de
                privacidad de tales terceros, y no ofrece ninguna garantía,
                explícita o implícita, en relación con la precisión, legalidad,
                exactitud o validez de cualquier sitio web de terceros o el
                tratamiento de datos que éstos terceros realicen. Si quieres
                saber cómo tratan tus datos personales tales terceros, por
                favor, consulta su política de privacidad a través del enlace
                facilitado en el sitio web.
              </p>
              <Title
                title={"¿Qué hacemos para garantizar la"}
                titleAlt={"seguridad y confidencialidad "}
                titleThird={"de tus datos personales?"}
              />
              <p>
                Tus datos personales se tratan a través de medios automatizados
                y no automatizados, y están protegidos por aquellas medidas de
                seguridad técnicas y organizativas que consideramos suficientes
                y adecuadas, teniendo en cuenta el tratamiento de datos a
                realizar y el estado de la tecnología actual.
              </p>
              <p>
                Para ello, restringimos de forma adecuada el acceso a la
                información personal e implementamos las medidas y controles
                necesarios, incluyendo medidas físicas y de supervisión, para
                almacenar y transferir los datos de manera segura y proteger los
                datos personales en su posesión contra cualquier pérdida, robo y
                uso, divulgación o modificación no autorizados.
              </p>
              <Title
                title={"¿Se dan "}
                titleAlt={"transferencias internacionales "}
                titleThird={"de datos?"}
              />
              <p>
                Los datos personales que recabamos pueden transferirse a,
                acceder desde, y almacenarse en nuestros servidores establecidos
                en España.
              </p>
              <p>
                Del mismo modo, para la prestación de nuestros servicios,
                podemos vernos en la necesidad de almacenar o dar acceso a tus
                datos personales a algún proveedor o alguna de nuestras
                entidades vinculadas localizadas fuera del Espacio Económico
                Europeo (“EEE”), en el Reino Unido o EE.UU. En cualquier caso,
                estas transferencias se realizarían sobre la base de la firma
                con tales empresas localizadas fuera del EEE de las cláusulas
                contractuales tipo autorizadas por la Comisión Europea (más
                información en{" "}
                <a
                  className="color-primary no-link"
                  href="https://www.aepd.es/es"
                >
                  <b>aepd.es</b>
                </a>
                ), que ofrecen una las garantías jurídicas adecuadas con arreglo
                a la normativa de protección de datos y salvaguardias definidas
                por la Unión Europea.
              </p>
              <p>
                Recuerda que tienes derecho a solicitar información adicional
                acerca del tratamiento de tus datos personales, en cualquier
                momento, contactando con nosotros a través de las direcciones
                indicadas en esta Política de Privacidad.
              </p>
              <Title
                titleAlt={"¿Cuáles son tus derechos"}
                titleThird={"cuando nos facilitas tus datos personales?"}
              />
              <p>
                Podrás ejercitar tus derechos de información, rectificación,
                oposición y cancelación, así como el derecho de limitación del
                tratamiento, la oposición a ser objeto de decisiones
                individuales automatizadas o portabilidad de tus datos
                personales dirigiendo comunicación por escrito a la Sociedad en
                la dirección arriba indicada o a través de la dirección de
                correo electrónico dpd@kolondoo.com. La petición deberá incluir
                nombre, apellidos, derecho que desea solicitar y contenido de su
                petición. Asimismo, la solicitud debe ir acompañada de una copia
                de tu documento de identidad, con el fin de comprobar la
                titularidad de los datos.
              </p>
              <p>
                Recuerda que, en cualquier momento, tienes la posibilidad y el
                derecho a retirar el consentimiento para cualquier finalidad
                específica otorgada en su momento, mediante el procedimiento
                descrito en el párrafo anterior, sin que ello afecte a la
                licitud del tratamiento basado en el consentimiento previo a su
                retirada.
              </p>
              <p>
                Asimismo, si consideras que el tratamiento de tus datos
                personales no es el adecuado puedes presentar reclamación ante
                la autoridad de control nacional competente, esto es, la Agencia
                Española de Protección de Datos (<b>aepd.es</b>).
              </p>
              <Title
                title={"Comunicaciones Comerciales y Declaración de"}
                titleAlt={"Buenas Prácticas"}
              />
              <p>
                La Sociedad no envía comunicaciones comerciales sin el
                consentimiento previo e informado del interesado conforme a la
                legislación vigente. A este respecto, te informamos de que
                cumplimos con la legislación vigente y, en particular, con los
                estándares de protección europeos en atención al Reglamento (UE)
                2016/679 del Parlamento Europeo y del Consejo de 27 de abril de
                2016 y normativa de desarrollo, garantizando los máximos
                estándares de protección en el tratamiento de tus datos
                personales (RPGD).
              </p>
              <p>
                El contenido de las comunicaciones remitidas por medios de
                comunicación electrónicos se ajusta a la normativa establecida
                en el RPGD, la Ley Orgánica 3/2018, de 5 de diciembre, de
                Protección de Datos Personales y garantía de los derechos
                digitales (“LOPDGDD”), y la Ley 34/2002, de 11 de julio, de
                servicios de la sociedad de la información y de comercio
                electrónico (“LSSI”).
              </p>
              <p>
                Todos los correos electrónicos incluyen un enlace para darse de
                baja de nuestras comunicaciones comerciales, así como las un
                enlace a esta política de privacidad con las indicaciones que
                deberás seguir en caso de que estés interesado en ejercer
                cualquiera de tus derechos en materia de protección de datos
                conforme a la legislación vigente.
              </p>
              <Title
                title={"Cambios en la "}
                titleAlt={"Política de Privacidad"}
              />
              <p>
                Nuestra Política de Privacidad podrá sufrir actualizaciones,
                como consecuencia de cambios y necesidades legales, así como
                debidas a mejoras y cambios incluidos en la forma de ofrecer y
                prestar nuestros servicios e información de interés. Por ello,
                te recomendamos que visites y accedas a nuestra Política de
                Privacidad periódicamente, para poder tener acceso y conocer los
                últimos cambios que hayan podido ser incorporados. En caso de
                que dichos cambios guarden relación con el consentimiento
                prestado, te será enviada una notificación independiente y por
                separado para recabarlo nuevamente.
              </p>
              <Title
                title={"¿Tienes alguna duda?"}
                titleAlt={"Contacta con nosotros"}
              />
              <p>
                Si tienes cualquier pregunta en relación con esta Política de
                Privacidad y el tratamiento de tus datos, por favor, contacta
                con nosotros a través de las siguientes direcciones:
              </p>
              <ul className="text-left icon-list">
                <li>
                  Dirección de correo electrónico: <b>dpd@kolondoo.com</b>
                </li>
                <li>
                  Dirección postal: KOLONDOO MEDIA, S.L., Calle Barquillo, 8
                  planta 1º izq., 28004 Madrid, España.
                </li>
              </ul>
              <br />
              <p>Última actualización: Diciembre 2021</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}
