import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, Tabs, Tab } from "react-bootstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { isMobile } from "react-device-detect";
import Modal from "react-bootstrap/Modal";
import InterSection from "../Utils/InterSection";
import TarjetaTarifa from "../Tarjeta/TarjetaTarifa";
import NotInfoItem from "../Utils/NotInfoItem";
import Load from "../Utils/Load";
import { useLocation } from "react-router-dom";
import { useFetchData } from "../../services/Query";

function ContenedorProductosFibra() {
  // Estado para filtros de precio y capacidad
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [typeMoneda, setTypeMoneda] = useState(null);

  // Estados para el estado de carga de filtros e información
  const [isLoadFilter, setIsLoadFilter] = useState(false);
  const [isLoadInformation, setIsLoadInformation] = useState(false);

  // Estados para filtros seleccionados
  const [filterBrand, setFilterBrand] = useState([]);
  const [filterPrice, setFilterPrice] = useState([minPrice, maxPrice]);
  const [filterPermanencia, setFilterPermanencia] = useState(false);
  const [filterPromo, setFilterPromo] = useState(false);
  const [filterOfertaDestacada, setFilterOfertaDestacada] = useState(false);

  // Estados para tarifas y marcas
  const [Tarifas, setTarifas] = useState([]);
  const [filtros, setFiltros] = useState([]);
  const [brand, setBrand] = useState([]);

  const [countParticulares, setCountParticulares] = useState(0);
  const [countEmpresarial, setCountEmpresarial] = useState(0);

  // Estados para rangos de precio y capacidad
  const [rangePrice, setRangePrice] = useState([minPrice, maxPrice]);

  const [lang, setLang] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setLang(location.pathname.split("/")[1]);
  }, [location]);
  // Estado para el modal de filtros
  const [show, setShow] = useState(false);

  // Función para limpiar los filtros
  const cleanFilter = () => {
    setFilterBrand([]);
    setFilterPrice([minPrice, maxPrice]);
    setRangePrice([minPrice, maxPrice]);
    setFilterPromo(false);
    setFilterOfertaDestacada(false);
    setFilterPermanencia(false);
    setFiltros(Tarifas);
  };

  // Función para manejar el cambio en el rango de precio
  const handleRangeChangePrice = (newRange) => {
    setRangePrice(newRange);
    handleFilterPrice(newRange);
  };

  // Llamada a la API para filtros iniciales
  const { data, isFetching, isError } = useFetchData("filterFibra", lang);

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      const { max_precio, min_precio, moneda } = data[0];

      if (max_precio && min_precio) {
        setMaxPrice(parseInt(max_precio));
        setMinPrice(Math.max(parseInt(min_precio), 0));
        setRangePrice([
          Math.max(parseInt(min_precio), 0),
          parseInt(max_precio),
        ]);
      }

      setTypeMoneda(moneda || "€");
      setIsLoadFilter(true);
    } else if (isError) {
      console.error("Error al obtener los datos iniciales.");
    }
  }, [data, isFetching, isError]);

  // Llamada a la API para obtener las marcas
  const { data: fetchedBrand } = useFetchData("Operadoras/fibra", lang);

  useEffect(() => {
    if (lang != null) {
      if (fetchedBrand) {
        setBrand(fetchedBrand);
        setIsLoadFilter(true);
        setIsLoadInformation(false);
      }
    }
  }, [lang, fetchedBrand]);

  // Llamada a la API para obtener las tarifas
  const { data: fetchedTarifas } = useFetchData("TarifasFibra", lang);

  // Función para obtener las tarifas de móvil
  useEffect(() => {
    if (lang != null) {
      setIsLoadInformation(true);
      if (fetchedTarifas) {
        setFiltros(fetchedTarifas);
        setTarifas(fetchedTarifas);
        const particulares = fetchedTarifas?.filter(
          (item) => item.tarifa_empresarial === 2
        ).length;
        const empresarial = fetchedTarifas?.filter(
          (item) => item.tarifa_empresarial === 1
        ).length;
        setCountParticulares(particulares);
        setCountEmpresarial(empresarial);
        setIsLoadInformation(false);
      }
    }
  }, [brand, lang, fetchedTarifas, filtros]);

  function setFilterBrandMulti(value) {
    if (!filterBrand?.includes(value)) {
      setFilterBrand([...filterBrand, value]);
    } else {
      setFilterBrand(filterBrand.filter((item) => item !== value));
    }
  }

  // Función para manejar el filtro de precio
  const handleFilterPrice = (value) => {
    setFilterPrice(value);
  };

  // Función para filtrar por marca
  const filterByBrand = useCallback(
    (item) => {
      if (filterBrand.length > 0) {
        return filterBrand.includes(item.operadora);
      }
      return true;
    },
    [filterBrand]
  );

  // Función para filtrar por precio
  const filterByPrice = useCallback(
    (item) =>
      filterPrice !== null
        ? item.precio >= filterPrice[0] && item.precio <= filterPrice[1]
        : true,
    [filterPrice]
  );

  // Función para filtrar por promoción
  const filterByPromo = useCallback(
    (item) =>
      filterPromo !== false
        ? item.promocion !== "" && item.promocion !== null
        : true,
    [filterPromo]
  );

  // Función para filtrar por oferta destacada
  const filterByOfertaDestacada = useCallback(
    (item) => {
      if (filterOfertaDestacada !== false) {
        return item.destacada === 1;
      }
      return true;
    },
    [filterOfertaDestacada]
  );

  // Función para aplicar los filtros
  useEffect(() => {
    const resultado = Tarifas.filter((item) => filterByBrand(item))
      .filter((item) => filterByPrice(item))
      .filter((item) => filterByPromo(item))
      .filter((item) => filterByOfertaDestacada(item));
    const particulares = resultado?.filter(
      (item) => item.tarifa_empresarial === 2
    ).length;
    const empresarial = resultado?.filter(
      (item) => item.tarifa_empresarial === 1
    ).length;
    setCountParticulares(particulares);
    setCountEmpresarial(empresarial);
    setFiltros(resultado);
  }, [
    filterBrand,
    filterPrice,
    filterPromo,
    filterOfertaDestacada,
    Tarifas,
    filtros,
    filterByBrand,
    filterByOfertaDestacada,
    filterByPrice,
    filterByPromo,
  ]);

  return (
    <>
      <section>
        <Container>
          <Row className="justify-content-around">
            <Col xs={12} md={12} xl={3}>
              <Row>
                {!isMobile ? (
                  <Col className="my-3 font-bold" xs={6} md={12} xl={5}>
                    Filtrar por:{" "}
                  </Col>
                ) : (
                  <Col className="my-2" xs={6} md={5}>
                    <Button variant="light" onClick={() => setShow(true)}>
                      Filtrar por
                    </Button>
                  </Col>
                )}
                <Col className="my-2 text-center" xs={6} md={7}>
                  <button className="btn btn-light" onClick={cleanFilter}>
                    Limpiar filtro
                  </button>
                </Col>
                <hr />
              </Row>
              {isMobile ? (
                <Modal show={show} onHide={() => setShow(false)}>
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <Row>
                      {isMobile && (
                        <Col xs={12} key={filterBrand} className="my-2" md={6}>
                          Se encontraron{" "}
                          <span className="font-bold">{filtros?.length}</span>{" "}
                          resultados de{" "}
                          <span className="font-bold">{Tarifas.length}</span>
                        </Col>
                      )}
                      <Col md={12}>
                        <span className="font-bold">Compañía:</span>
                      </Col>
                      {brand.map((item, index) => (
                        <Col xs={4} md={6} key={item.id}>
                          <button
                            className={`filtro-producto-logo my-2 ${
                              filterBrand.includes(item.id) ? "logoFocus" : ""
                            }`}
                            value={item.nombre}
                            onClick={() => setFilterBrandMulti(item.id)}
                          >
                            <img src={item.logo} alt={item.nombre} />
                          </button>
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <div className="mt-4">
                        <b>{"Coste mensual"}:</b>
                        <div className="my-4 d-flex justify-content-between">
                          <div>
                            {typeMoneda}
                            {rangePrice[0]
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </div>
                          <div>
                            {typeMoneda}
                            {rangePrice[1]
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </div>
                        </div>
                        <Slider
                          range
                          min={minPrice}
                          max={maxPrice}
                          value={rangePrice}
                          onChange={handleRangeChangePrice}
                          className="form-input-range"
                        />
                      </div>
                      <div className="mt-4">
                        <b>{"Oferta destacada"}:</b>
                        <div className="my-2">
                          <Form.Switch
                            className="input-check-dark mt-2 text-left"
                            type="switch"
                            checked={filterOfertaDestacada}
                            onChange={() =>
                              setFilterOfertaDestacada(!filterOfertaDestacada)
                            }
                            label={"Mostrar solo ofertas destacadas"}
                            reverse
                          />
                        </div>
                      </div>
                      <div className="my-2">
                        <b>{"Permanencia"}:</b>
                        <Form.Switch
                          className="input-check-dark mt-2 text-left"
                          type="switch"
                          checked={filterPermanencia}
                          onChange={() =>
                            setFilterPermanencia(!filterPermanencia)
                          }
                          label={"Tarifa sin permanencia"}
                          reverse
                        />
                      </div>
                      <div className="my-2">
                        <b>{"Promoción"}:</b>
                        <div className="my-2">
                          <Form.Switch
                            className="input-check-dark mt-2 text-left"
                            type="switch"
                            checked={filterPromo}
                            onChange={() => setFilterPromo(!filterPromo)}
                            label={"Tiene promoción"}
                            reverse
                          />
                        </div>
                      </div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={() => setShow(false)}>
                      Filtrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : (
                <>
                  {isLoadFilter && !isLoadInformation ? (
                    <div>
                      <Row>
                        {isMobile && (
                          <Col
                            xs={12}
                            key={filterBrand}
                            className="my-2"
                            xl={6}
                          >
                            Se encontraron{" "}
                            <span className="font-bold">{filtros?.length}</span>{" "}
                            resultados de{" "}
                            <span className="font-bold">{Tarifas.length}</span>
                          </Col>
                        )}
                        <Col md={12}>
                          <span className="font-bold">Compañía:</span>
                        </Col>
                        {brand?.map((item, index) => (
                          <Col xs={4} xl={6} key={item.id}>
                            <button
                              key={index}
                              className={`filtro-producto-logo my-2 ${
                                filterBrand.includes(item.id) ? "logoFocus" : ""
                              }`}
                              value={item.nombre}
                              onClick={() => setFilterBrandMulti(item.id)}
                            >
                              <img src={item.logo} alt={item.nombre} />
                            </button>
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        <div className="mt-4">
                          <b>{"Coste mensual"}:</b>
                          <div className="my-4 d-flex justify-content-between">
                            <div>
                              {typeMoneda}
                              {rangePrice[0]
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            </div>
                            <div>
                              {typeMoneda}
                              {rangePrice[1]
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            </div>
                          </div>
                          <Slider
                            range
                            min={minPrice}
                            max={maxPrice}
                            value={rangePrice}
                            onChange={handleRangeChangePrice}
                            className="form-input-range"
                          />
                        </div>
                        <div className="mt-4">
                          <b>{"Oferta destacada"}:</b>
                          <div className="my-2">
                            <Form.Switch
                              className="input-check-dark mt-2 text-left"
                              type="switch"
                              checked={filterOfertaDestacada}
                              onChange={() =>
                                setFilterOfertaDestacada(!filterOfertaDestacada)
                              }
                              label={"Mostrar solo ofertas destacadas"}
                              reverse
                            />
                          </div>
                        </div>
                        <div className="my-2">
                          <b>{"Permanencia"}:</b>
                          <Form.Switch
                            className="input-check-dark mt-2 text-left"
                            type="switch"
                            checked={filterPermanencia}
                            onChange={() =>
                              setFilterPermanencia(!filterPermanencia)
                            }
                            label={"Tarifa sin permanencia"}
                            reverse
                          />
                        </div>
                        <div className="my-2">
                          <b>{"Promoción"}:</b>
                          <div className="my-2">
                            <Form.Switch
                              className="input-check-dark mt-2 text-left"
                              type="switch"
                              checked={filterPromo}
                              onChange={() => setFilterPromo(!filterPromo)}
                              label={"Tiene promoción"}
                              reverse
                            />
                          </div>
                        </div>
                      </Row>
                    </div>
                  ) : (
                    <Load />
                  )}
                </>
              )}
            </Col>
            <Col md={12} xl={8}>
              <Row>
                <Col key={filterBrand} className="my-2" md={6}>
                  Mostrando:{" "}
                  <span className="font-bold">{filtros?.length}</span>{" "}
                  resultados de{" "}
                  <span className="font-bold">{Tarifas.length}</span>
                </Col>
              </Row>
              <Row>
                {countParticulares === 0 && countEmpresarial === 0 ? (
                  <NotInfoItem
                    title="No se encontraron ofertas"
                    text="Lo sentimos, no hemos encontrado ofertas con los filtros seleccionados."
                  />
                ) : (
                  <Tabs
                    defaultActiveKey="particulares"
                    id="tabs_filtros"
                    className="mb-3"
                  >
                    {countParticulares > 0 && (
                      <Tab
                        eventKey="particulares"
                        title={
                          <>
                            Tarifas para particulares{" "}
                            <span className="badge bg-secundary color-dark ms-2">
                              {
                                filtros?.filter(
                                  (item) => item.tarifa_empresarial === 2
                                ).length
                              }
                            </span>
                          </>
                        }
                      >
                        {(() => {
                          const filteredTarifas = filtros?.filter(
                            (item) => item.tarifa_empresarial === 2
                          );

                          return !isLoadInformation ? (
                            filteredTarifas?.length > 0 ? (
                              filteredTarifas.map((item, index) => (
                                <TarjetaTarifa
                                  key={index}
                                  data={item}
                                  TarifaCard
                                />
                              ))
                            ) : (
                              <NotInfoItem
                                title="No se encontraron ofertas"
                                text="Lo sentimos, no hemos encontrado ofertas con los filtros seleccionados."
                              />
                            )
                          ) : (
                            <Load />
                          );
                        })()}
                      </Tab>
                    )}

                    {countEmpresarial > 0 && (
                      <Tab
                        eventKey="empresariales"
                        title={
                          <>
                            Tarifas para empresas{" "}
                            <span className="badge bg-secundary color-dark ms-2">
                              {countEmpresarial}
                            </span>
                          </>
                        }
                      >
                        {(() => {
                          const filteredTarifas = filtros?.filter(
                            (item) => item.tarifa_empresarial === 1
                          );

                          return !isLoadInformation ? (
                            filteredTarifas?.length > 0 ? (
                              filteredTarifas.map((item, index) => (
                                <TarjetaTarifa
                                  key={index}
                                  data={item}
                                  TarifaCard
                                />
                              ))
                            ) : (
                              <NotInfoItem
                                title="No se encontraron ofertas"
                                text="Lo sentimos, no hemos encontrado ofertas con los filtros seleccionados."
                              />
                            )
                          ) : (
                            <Load />
                          );
                        })()}
                      </Tab>
                    )}
                  </Tabs>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <InterSection></InterSection>
    </>
  );
}

export default ContenedorProductosFibra;
