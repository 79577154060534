import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import { Container, Row, Col } from 'react-bootstrap'
import Title from '../../Components/Text/Title'
import MetaData from '../../Components/Header/SeoMetadata';

export default function PoliticaLegal() {
    return (
        <>
        <MetaData titulo={''} descripcion={''}/>
            <Header breadCrumb></Header>
            <div className='mb-5'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <Title title={'Aviso'} titleAlt={'Legal'} />
                            <p>A continuación, desde Kolondoo queremos explicar los términos y condiciones generales de uso aplicables al acceder al sitio web y/o utilizar los servicios ofrecidos en el mismo.</p>
                            <p>Los presentes términos y condiciones generales de uso (en adelante, los “Términos de Uso”) regulan los derechos y obligaciones derivados del acceso, la navegación y el uso por parte de los usuarios del dominio <a className='color-primary no-link' href='https://www.vuskoo.com'><b>https://vuskoo.com</b></a> (en adelante, el “ Sitio Web”).</p>
                            <Title title={'1. - Responsable'} />
                            <p>El titular del Sitio Web y resposanle del tratamiento de los datos que se recaben es KOLONDOO MEDIA S.L. (en adelante, “Kolondoo” o la “Sociedad”) con DNI/ NIF: B-16756041 y domicilio social: C/Barquillo nº8. Iº1zq , 28004 Madrid, (España). Datos registrales: Registro Mercantil de Madrid, Tomo 42273, Folio 180, Inscripción 1, Hoja M-748395 E-mail de contacto hola@kolondoo.com o través de la sección de “Contacto” habilitada a tal efecto en el Sitio Web.</p>
                            <Title title={'2. - Acceso, navegación y seguridad'} />
                            <p>El acceso, navegación, registro y uso en el Sitio Web, supone aceptar expresamente y conocer las advertencias legales, condiciones y términos de uso contenidos en el Sitio Web en la versión publicada en el momento en que se accede sin reserva alguna. A los efectos de los presentes Términos de Uso, nos referiremos tanto a los usuarios registrados como no registrados de forma conjunta, como los “Usuarios”.</p>
                            <p>En consecuencia, el Usuario declara y reconoce: (i) Haber leído, entendido y comprendido íntegramente los presentes Términos de Uso, <a className='color-primary no-link' href='/es/politica-cookies'><b>Política de Cookies</b></a> y <a className='color-primary no-link' href='politica-privacidad'><b>Política de Privacidad</b></a>; (ii)Ser persona con capacidad suficiente para contratar, siendo el único responsable de la veracidad y exactitud de los datos proporcionados; y (iii)Asumir todas las obligaciones aquí dispuestas.</p>
                            <p>Por el contrario, si no está de acuerdo con estos Términos de Uso o parte de ellos, puede abstenerte de utilizar nuestro Sitio Web y/o los servicios que se ofrecen.</p>
                            <p>En Kolondoo realizamos los máximos esfuerzos para que la navegación se realice en las mejores condiciones posibles y evitar fallos durante la misma. Sin embargo, no nos responsabilizamos ni garantizamos que el acceso a este Sitio web sea ininterrumpido o que esté libre de error. Tampoco se garantiza que el contenido al que pueda accederse a través de este Sitio web, esté libre de error o cause un daño.</p>
                            <p>Asimismo, la Sociedad se reserva el derecho a monitorizar el acceso y/o la utilización del Sitio Web así como de adoptar medidas técnicas necesarias con el objetivo de verificar que se está realizando un uso acorde a los presentes Términos de Uso y la legislación vigente y, en consecuencia, se reserva, sin necesidad de previo aviso, el derecho a suspender, eliminar, limitar o impedir el acceso al Sitio Web y/o los servicios cuando surjan anomalías o dificultades técnicas por hechos o circunstancias ajenas a su control que (i) disminuyan o anulen los niveles de seguridad adoptados para el adecuado funcionamiento del mismo o (ii) por razones de mantenimiento; o denegar el acceso al Sitio Web a los Usuarios en caso de que no se respeten los presentes Términos de Uso y demás avisos legales contenidos en el Sitio Web.</p>
                            <p>Se recomienda leer periódicamente los presentes Términos de Uso cada vez que se accede al Sitio Web y/o nuestros servicios.</p>
                            <Title title={'3. - Servicios'} />
                            <p>La finalidad de Kolondoo es la de proporcionar un Sitio Web que funcione como comparador de tarifas o precios de servicios y productos de terceras empresas de diversos sectores principalmente de “Internet y Telefonía”, “Luz, agua y gas” o “Energía” entre otros, y permitir, en su caso, la consiguiente contratación operando para estos productos y servicios como una empresa de servicios conforme a la Ley 34/2002 de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (en adelante, designados de forma conjunta, los “Servicios”).</p>
                            <p>Kolondoo podrá intermediar en la formalizacion de los contratos con las empresas cuyos servicios y productos ofrece a través del Sitio Web. No obstante, los contratos de prestación de los productos y servicios ofrecidos serán exclusivamente vinculantes con los titulares de los mismos, es decir, las terceras empresas que ofrecen los productos o servicios contenidos en el Sitio Web.</p>
                            <p>Kolondoo ofrecerá a los Usuarios, de forma general o con carácter personalizado en atención a la información que hubiera sido facilitada, las tarifas, ofertas y precios disponibles para la solicitud en cuestión (en lo sucesivo, conjuntamente denominados como la “Oferta”). La Oferta incluirá IVA, tasas y cualquier otro gasto o impuesto que resulte de aplicación. Cualquier importe o impuesto no incluido se detallará en la correspondiente Oferta, siendo obligación de los Usuarios leer atentamente las condiciones de la mismas.</p>
                            <p>Kolondoo podrá enviar las Ofertas disponibles para un determinado producto o servicio por correo electrónico, en los casos que en que los Usuarios hayan facilitado su correo a tal efecto. No obstante, Kolondoo no interviene en la prestación final del servicio o producto contratado, actuando como un mero comparador e intermediario en caso de que se decida contratar alguno de los productos o servicios ofrecidos en el Sitio Web. Para recibir información adicional o tramitar una Oferta, Kolondoo pone a disposición de los Usuarios diferentes medios: (i) ser redireccionado a la página de la empresa proveedora del servicio o producto elegido, para que pueda contratar la Oferta directamente a través de su página web del proveedor; (ii) obtener el/los números de contacto del proveedor del servicio o producto para contratar telefónicamente la Oferta con éste; o (iii) proporcionar el número de teléfono para ser contactado por teléfono,de forma gratuita,por el proveedor o el propio Kolondoo y contratar la Oferta escogida. En cualquier caso, se recuerda a los Usuarios que las Ofertas son proporcionadas directamente por las terceras empresas proveedoras del servicio o producto de interés para el Usuario. Las mismas pueden ser modificadas en cualquier momento por sus proveedores, por lo que se recomenda a los Usuarios que verifiquen con las terceras entidades mercantiles, con carácter previo a su contratación, las condiciones finales de contratación. Kolondoo no asume ninguna responsabilidad derivada de la disponibilidad, veracidad, alcance o exactitud de dichas Ofertas ni de las posibles contingencias o perjuicios derivados del uso o disfrute del producto o servicio contratado con el proveedor del mismo.</p>
                            <p>Una vez contratada la Oferta, durante un plazo de catorce (14) días naturales a contar desdela fecha de contratación, el Usuario podrá ejercer su derecho de desistimiento sobre el servicio o producto contratado, sin necesidad de justificar el motivo y sin penalización. Para ello solo tendrán que ponerse en contacto con Kolondoo o el tercero proveedor del producto o servicio contratado por cualquiera de las vías habilitadas al efecto y se le informará de cómo ejercer su derecho de desestimiento.</p>
                            <p>La utilización de ciertos Servicios ofrecidos a los Usuarios puede encontrarse sometidos a condiciones particulares propias o de los terceros cuyos servicios o productos se ofrecen en el Sitio Web, que, según los casos sustituyen, completan y/o modifican los presentes Términos de Uso según proceda.</p>
                            <p>Cualquiera de los Servicios ofrecidos se ofrece a personas mayores de 18 años residentes o domiciliadas en España. Los menores de edad no podrán usar los Servicios ofrecidos sin la previa autorización de sus padres, tutores o representantes legales, quienes serán los únicos responsables de todos los actos realizados a través del Sitio Web por los menores a su cargo, incluyendo la cumplimentación de los formularios con los datos personales y la marcación, en su caso, de las casillas que los acompañan y la contratación de los Servicios si procede.</p>
                            <p>Los Servicios de Kolondoo se ofrecen de forma gratuita, ya que la Sociedad obtiene sus beneficios de las comisiones de las empresas cuyos productos y/o servicios se comparan, median o intermedian, según el servicio o producto en cuestión.</p>
                            <p>Kolondoo se reserva la posibilidad de modificar sin previo aviso, el diseño, presentación y/o configuración del Sitio Web, así como añadir Servicios nuevos y/o modificar los existentes. En todo caso, para aquellos supuestos en que se produjera una modificación sustancial en la forma de prestar los Servicios con respecto a los ya aceptados por los Usuarios, se realizarán las comunicaciones oportunas.</p>
                            <Title title={'4.- Proceso de registro de los Usuarios'} />
                            <p>El acceso al Sitio Web no requiere el registro previo. No obstante, para consultar determinados contenidos del Sitio Web, contratar una determinda Oferta así como recibir los boletines de noticias, será necesario el registro del Usuario utilizando el procedimiento oportuno.Al registrarse o solicitar un Servicio, el Usuario acepta la totalidad de nuestros textos legales contenidos en el Sitio Web, siendo éstos plenamente vinculantes. Por ello, la Sociedad recomienda su lectura íntegra antes de proceder al registro,en especial, la <a className='color-primary no-link' href='/es/politica-privacidad'><b>Política de Privacidad</b></a>.</p>
                            <p>Todos los datos requeridos en el proceso de alta son debidamente indicados con un asterisco (*) y deben ser completados necesariamente por lo que, la no cumplimentación de los mismos, liberará a Kolondoo de cualquier obligación u acción vinculada a la dicha información, pudiendo denegarse el alta como Usuario y/o la prestación de los Servicios asociades a dichos datos.</p>
                            <p>Toda la información que se facilite como Usuario deberá ser exacta,veraz y actual. A estos efectos, el Usuario garantiza la titularidad, autenticidad y actualización en todo momento de aquellos datos que comunique como consecuencia de la cumplimentación de los formularios habilitados en el Sitio Web, siendo el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a Kolondoo y/o a terceros por la información que facilite. Del mismo modo, debe tener en cuenta que en caso de que los datos e información que proporciones a tal efecto no sea cierta, el proveedor de los productos o servicios escogidos puede decidir no proporcionalos o modificar las condiciones de contratación.</p>
                            <p>Los formularios contenidos en el Sitio Web se ofrecen en idioma español y se deberán responderse en este mismo idioma , salvo que expresamente se permita o se dé la posibilidad de usar otra lengua o idioma.</p>
                            <Title title={'5.- Obligaciones como Usuario'} />
                            <p>El Usuario se compromete a hacer un uso diligente, correcto y lícito del Sitio Web y de los Servicios accesibles desde el mismo, con total sujeción a la Ley, a las buenas costumbres y a los presentes Términos de Uso (incluyendo las condiciones particulares en su caso y demás textos legales).</p>
                            <p>Será responsabilidad del Usuario mantener toda la información facilitada a Kolondoo permanentemente actualizada de forma que responda, en cada momento a la situación real del Usuario. De igual forma, el Usuario es el único responsable de las relaciones que establezca con el resto de empresas cuyos servicios o productos se ofrecen, siendo, en todo caso, el Usuario el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a Kolondoo o a terceros por la información que facilite.</p>
                            <p>Los Usuarios podrán contactar con la Sociedad e interactuar en determinadas secciones del Sitio Web, con el contenido de las Newsletters o compartiendo contenidos o secciones en redes sociales.No obstante, queda prohibido: (i) hacerse pasar por otra persona u organización; (ii) utilizar un lenguaje irrespetuoso y ofensivo. No son admisibles mensajes con amenazas, insultos graves o cualquier otro tipo de comentario que pueda herir la sensibilidad; (iii) presentar, citar y recomendar empresas, o portales web, y, en general, realizar acciones de competencia deslegal, marketing o spam. En caso contrario, es susceptible de incurrir en falta o delito de acuerdo con lo establecido en el Código Penal y el Código Civil Español.</p>
                            <Title title={'6.- Publicación de contenidos y enlaces'} />
                            <p>El Sitio Web ofrece los Servicios y/o contenidos relacionados con la actividad de Kolondoo y los servicios y productos de terceros, siendo éstos meramente informativos, sin que en ningún caso se garantice la exactitud de los mismos. El Sitio Web puede contener enlaces a dominios de terceros cuya propiedad y gestión corresponde a dichos terceros independientes sobre los que Kolondoo no tiene ningún tipo de control ni propiedad.</p>
                            <p>Kolondoo no garantiza ni se responsabiliza, en modo alguno, de aquellos contenidos, actividades comerciales, productos y servicios que puedan enlazarse, directa o indirectamente, con el Sitio Web. La presencia de tales enlaces, salvo manifestación expresa en contrario, tiene una finalidad únicamente informativa y, en ningún caso, supone sugerencia, invitación o recomendación sobre los mismos o éstos tengan carácter vinculante para Kolondoo, correspondiendo al Usuario verificar cuantas informaciones considere oportunas.</p>
                            <Title title={'7.- Derechos de Propiedad Intelectual e Industrial'} />
                            <p>A título enunciativo no limitativo, los textos, diseños, imágenes, audio, bases de datos, imágenes, logos, estructura, marcas y demás elementos del Sitio Web están protegidos por los derechos de propiedad intelectual e industrial de Kolondoo y/o de terceros titulares con las debidas autorizaciones.</p>
                            <p>Kolondoo se reserva todos los derechos de explotación sobre la propiedad intelectual e industrial contenida en el Sitio Web por lo que, en ningún caso, se entenderá que el acceso, navegación y uso de mismo implica una renuncia, transmisión, licencia o cesión total o parcial de dichos derechos.</p>
                            <p>Cualquier reproducción, transmisión o distribución, adaptación, traducción, modificación, comunicación al público, o cualquier otra explotación de todo o parte del contenido del Sitio Web, efectuada de cualquier forma o por cualquier medio está estrictamente prohibido salvo autorización previa por escrito de Kolondoo o de terceros titulares. Cualquier infracción de estos derechos podrá dar lugar a cuantos procedimientos extrajudiciales o judiciales civiles o penales correspondan.</p>
                            <p>Se permite establecer un enlace al Sitio Web siempre y cuando no se relacione con manifestaciones falsas, inexactas, incorrectas, que puedan inducir a error o confusión o que sean contrarias a la ley, la moral o las buenas costumbres. No obstante, queda prohibido alterar, cambiar, modificar o adaptar el Sitio Web de forma alguna. El Usuario dispone únicamente de un derecho de uso estrictamente privado con la finalidad de disfrutar de los contenidos y Servicios conforme a los presentes Términos de Uso.</p>
                            <Title title={'8. - Exclusión de Garantías y Responsabilidad'} />
                            <p>Kolondoo se esfuerza por evitar cualquier error en los contenidos que pudieran aparecer en el Sitio Web. No obstante, no garantiza, ni responsabiliza de las consecuencias que pudieran derivarse de los errores en los contenidos propios o de terceros que pudieran aparecer ni asume responsabilidad alguna derivada de la veracidad, exhaustividad, exactitud, licitud y/o fiabilidad de la información proporcionada, por lo que se reserva el derecho a actualizar, modificar o eliminar los contenidos, servicios y, en general, cuantos elementos integran el diseño y configuración del Sitio Web en cualquier momento sin necesidad de preaviso.</p>
                            <p>Kolondoo ha adoptado, dentro de sus posibilidades y del estado actual de la tecnología, todas las medidas razonables a fin de garantizar el correcto funcionamiento del Sitio Web y tratará de solucionar los problemas que puedan surgir y alcanzar una solución rápida y satisfactoria frente a posibles incidencias. Sin embargo, en ningún caso garantiza la fiabilidad, la disponibilidad ni la continuidad del Sitio Web, por lo que la utilización del mismo por parte de los Usuarios se lleva a cabo por su propia cuenta y riesgo, sin que, en ningún momento, puedan exigirse responsabilidades a Kolondoo en este sentido.</p>
                            <ol type='I'>
                                <li>Asimismo, la Sociedad no asume responsabilidad alguna, a título enunciativo no limitativo, derivada de:</li>
                                <li>La utilización que se haga de los contenidos y Ofertas dispuestos en el Sitio Web, ya sean prohibidos o permitidos, en infracción de los derechos de propiedad intelectual y/o industrial de la Sociedad o de terceros.</li>
                                <li>Los contenidos de aquellas páginas a las que los Usuarios puedan acceder desde enlaces incluidos en el Sitio Web.</li>
                                <li>Los actos u omisiones de terceros, con independencia de que estos terceros pudiesen estar unidos a Kolondoo mediante vía contractual.</li>
                                <li>Los problemas ocasionados a los sistemas informáticos o los provocados por la introducción de virus y otros códigos informáticos, así como los posibles errores de seguridad, fallos o incidencias que pudieran producirse en las comunicaciones, borrado o transmisiones incompletas que pudieran ocurrir. Es responsabilidad de cada Usuario disponer de las herramientas adecuadas para la detección de programas informáticos dañinos.</li>
                                <li>Errores o retrasos en el acceso al Sitio Web a la hora de introducir los datos en el formulario correspondiente o cualquier anomalía que pueda surgir cuando estas incidencias sean debidas a problemas en la red internet, causas de caso fortuito o fuerza mayor y cualquier otra contingencia imprevisible ajena a la buena fe y control de Kolondoo.</li>
                                <li>Los errores o daños producidos por un uso del Sitio Web ineficiente y de mala fe por parte de Usuario.</li>
                            </ol>
                            <p>El Usuario garantiza a Kolondoo que cumple rigurosamente los presente Términos de Uso eximiendo a la Sociedad de cualquier potencial responsabilidad al respecto, debiendo mantener indemne y, en su caso, resarcir e indemnizar a Kolondoo, sus directivos, empleados, afiliados, agentes y cualesquiera otras personas vinculadas, respecto de cualesquiera reclamaciones, responsabilidades, sanciones, daños y perjuicios, pérdidas, gastos o cualesquiera otros conceptos que sean consecuencia de cualquier incumplimiento de los presentes Términos de Uso.</p>
                            <Title title={'9.- Tratamiento de Datos y Cookies'} />
                            <p>Por visitar el presente Sitio Web no queda registrado de forma automática ningún dato de carácter personal que identifique a un Usuario, en cambio existe determinada información de carácter no personal y no identificable con un Usuario concreto que se recoge durante la sesión en directo para a través de dispositivos denominados “cookies” que nos permiten obtener información estadística sobre el uso del Sitio Web para luego poder realizar mejoras. Todo Usuario debe consultar la <a className='color-primary no-link' href='/es/politica-cookies'><b>Política De Cookies</b></a> para navegar por el Sitio Web.</p>
                            <p>Del mismo modo, de acuerdo con lo establecido en la normativa de protección de datos, se informa a los Usuarios de que los datos personales que nos facilite a través del Sitio Web o mediante envíos de correos electrónicos o llamadas telefónicas, serán tratados por la Sociedad, bajo la legitimidad otorgada por el consentimiento prestado por el usuario para las finalidades descritas en la <a className='color-primary no-link' href='/es/politica-privacidad'><b>Política de Privacidad</b></a>, la cual debe ser leída, comprendida y aceptada para la utilización del presente Sitio Web.</p>
                            <p>Kolondoo, comprometida con el cumplimiento de la obligación de secreto y seguridad de los datos de carácter personal, ha adoptado las medidas necesarias para evitar su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta en todo momento del estado de la tecnología.</p>
                            <p>El Usuario responderá, en cualquier caso, de la veracidad de los datos facilitados y podrá ejercitar los derechos de acceso, supresión, rectificación, oposición, limitación del tratamiento y portabilidad, mediante escrito dirigido a Kolondoo, bien a su domicilio social, bien a través del correo electrónico del delegado de protección de datos dpo@kolondoo.es siempre y cuando acredite su identidad. En cualquier caso, si como Usuario considera que sus datos no son tratados de acuerdo con la legislación aplicable en materia de protección de datos, puede reclamar ante la Agencia Española de Protección de Datos</p>
                            <Title title={'10.- Legislación aplicable y jurisdicción competente'} />
                            <p>Cualquier controversia o conflicto que pudiera derivarse del acceso, la utilización del Sitio Web se regirá de acuerdo con lo establecido en la legislación española y sometido a la exclusiva jurisdicción de los Juzgados y Tribunales de Madrid. No obstante lo anterior, de conformidad con la legislación española, para cuantas cuestiones pudieran surgir en relación con la contratación de los productos y servicios distribuidos por Kolondoo, serán competentes los juzgados y tribunales del domicilio del consumidor. Pudiendo, además, presentar la reclamación oportuna en el siguiente enlace: <a className='color-primary no-link' href='https://ec.europa.eu/consumers/odr'><b>https://ec.europa.eu/consumers/odr</b></a></p>
                            <Title title={'11.- Quejas y reclamaciones'} />
                            <p>En Kolondoo, disponemos de un servicio de atención al cliente encargado de dar respuesta a las dudas que puedas tener en torno a los Servicios propios, las Ofertas contenidas en el Sitio Web y proporcionar, en su caso, la asistencia necesaria para la contratación de las mismas a través de los diferentes canales habilitados al efecto.</p>
                            <p>Las quejas y reclamaciones en relación a la prestación de los Servicios propio de Kolondoo deberán remitirse, en todo caso, a hola@kolondoo.com. Para consultas relacionadas con los productos o servicios de terceros una vez contratados,los Usuarios deberán digirse a los respectivos prestadores del servio o producto en cuestión.</p>
                            <Title title={'12.- Cambios en los Términos de Uso'} />
                            <p>Kolondoo se reserva el derecho de modificar los presentes Términos de Uso debido a actualizaciones o cambios y necesidades legales, así como debido a mejoras y cambios incluidos en la forma en que la Sociedad ofrece y presta sus Servicios.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer></Footer>
        </>
    )
}
