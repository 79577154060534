import { React, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import TitleSection from "../Text/TitleSection";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  DataStreaming,
  responsive,
} from "../../constants/ContenedorComparadorLogos";
import { useFetchData } from "../../services/Query";

function ContenedorComparadorLogos({ subtitle }) {
  const [endpoint, setEndpoint] = useState(null);
  const [lang, setLang] = useState(null);
  const location = useLocation();
  const { t } = useTranslation();

  // Determinar idioma y endpoint dinámicamente
  useEffect(() => {
    const pathLang = location.pathname.split("/")[1];
    setLang(pathLang);

    switch (location.pathname.replace("/", "")) {
      case "es/internet-telefonia":
        setEndpoint("OperadorasAll");
        break;
      case "es/energia":
        setEndpoint("ComercializadorasAll");
        break;
      case "television_y_streaming":
        setEndpoint(null); 
        break;
      default:
        setEndpoint(null);
        break;
    }
  }, [location]);

  // Usar React Query para las solicitudes
  const { data: infoLogo, isLoading, error } = useFetchData(endpoint, lang);

  // Manejar errores locales o datos predeterminados
  const logos = endpoint ? infoLogo : DataStreaming;

  return (
    <div>
      <TitleSection
        title={t("ComparadorLogosTitle")}
        titleAlt={t("ComparadorLogosAlt")}
        subtitle={subtitle}
        center
      />
      <Container>
        <Row>
          <Col xs={12} md={8} className="mx-auto mt-md-4">
            <div
              style={{
                paddingBottom: "30px",
                position: "relative",
              }}
            >
              {isLoading && <p>Cargando...</p>}
              {error && <p>Error al cargar datos</p>}
              {logos?.length > 0 && (
                <Carousel
                  className="text-center"
                  additionalTransfrom={0}
                  autoPlay
                  autoPlaySpeed={1500}
                  arrows={false}
                  centerMode={false}
                  containerClass="container"
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  infinite
                  keyBoardControl
                  minimumTouchDrag={80}
                  renderDotsOutside={false}
                  responsive={responsive}
                  rewind={false}
                  rewindWithAnimation={false}
                  rtl={false}
                  shouldResetAutoplay
                  showDots={false}
                  sliderClass=""
                  slidesToSlide={1.5}
                  swipeable
                >
                  {logos?.map((data, key) => (
                    <Image
                      style={{ width: "100px", height: "100%" }}
                      draggable={false}
                      src={data.logo}
                      key={key}
                    />
                  ))}
                </Carousel>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContenedorComparadorLogos;
