import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "rc-slider/assets/index.css";
import InterSection from "../Utils/InterSection";
import TarjetaTarifaStreaming from "../Tarjeta/TarjetaTarifaStreaming";
import Carousel from "react-multi-carousel";
import Load from "../Utils/Load";
import { useLocation } from "react-router-dom";
import { useFetchData } from "../../services/Query";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function ContenedorProductosStreaming(
  logo = null,
  landingLead = null,
  id = null
) {
  const [isLoadInformation, setIsLoadInformation] = useState(true);
  const [Tarifas, setTarifas] = useState([]);

  const [lang, setLang] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setLang(location.pathname.split("/")[1]);
  }, [location]);

  // Llamada a la API para obtener las tarifas
  const { data: fetchedTarifas } = useFetchData("TarifasStreaming", lang);

  useEffect(() => {
    if (lang != null) {
      setIsLoadInformation(true);
      if (fetchedTarifas) {
        setTarifas(fetchedTarifas);
        setIsLoadInformation(false);
      }
    }
  }, [lang, fetchedTarifas]);

  return (
    <>
      {!isLoadInformation ? (
        <Container>
          <div
            style={{
              paddingBottom: "10px",
              position: "relative",
            }}
          >
            {Tarifas?.length > 0 && (
              <Carousel
                arrows={true}
                centerMode={false}
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                keyBoardControl
                pauseOnHover
                renderDotsOutside={true}
                responsive={responsive}
                rewind={true}
                showDots={false}
                slidesToSlide={1}
              >
                {Tarifas?.map((item, index) => {
                  return <TarjetaTarifaStreaming data={item} key={index} />;
                })}
              </Carousel>
            )}
          </div>
          {lang === "es" && (
            <small>
              *Se pueden añadir pases de suscriptor/a extra** por 5,99 € al mes
            </small>
          )}
        </Container>
      ) : (
        <Load></Load>
      )}
      <InterSection></InterSection>
    </>
  );
}

export default ContenedorProductosStreaming;
